<template>
  <b-row>
    <b-col sm="12">

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Diseño</h4>
            <div class="small text-muted">Diseño de personalizado de reportes</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col md="4">
            <FindObject render="search"
                        type="reports" 
                        @select-object="loadReports($event)" 
                        :valueID="crud.form.id"/>                 
          </b-col>  

          <b-col md="1" v-if="crud.form.reports && !crud.form.reports.only_detail">
            <b-form-group label="ID" v-if="contentRender!='[[no-config]]' && contentRender!='[[no-report]]'">
              <b-form-input type="number"
                            size="sm"
                            v-model="filter.id"
                            required
                            placeholder="Ingresar un ID">
              </b-form-input>
            </b-form-group>                  
          </b-col>       
          <b-col md="1" v-if="contentRender!='[[no-config]]' && contentRender!='[[no-report]]'">                      
            <b-button variant="info"                             
                      class="reports-design-button-filter" 
                      size="sm"
                      @click="filterReports()">
                Filtrar
            </b-button>          
          </b-col>                              
        </b-row>
        
        <b-row>
          <b-col lg="3">     
            <b-tabs class="mt-3">
              <b-tab title="Disponibles" active>
                <b-row>           
                  <b-col md="12">                                                  
                    <json-tree v-if="jsonFilter" :data="jsonFilter" :level="1" class="reports-design-pre-json"></json-tree>                  
                    <b-alert v-else variant="warning" show>No hay datos para mostrar</b-alert>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Utilizados">                  
                <json-tree v-if="variablesUsed" :data="variablesUsed" class="reports-design-pre-json"></json-tree>                  
                <b-alert v-else variant="warning" show>Aun no se utilizó ninguna variable</b-alert>           
              </b-tab>
              <b-tab title="Ayuda">
                <b>Variables</b><br>
                <div class="mt-1">
                  <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(0)}}<br>
                </div>
                <div class="mt-1">
                  <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(1)}}<br>
                </div>
                <div class="mt-1">
                  <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(2)}}<br>
                </div>
                <div class="mt-1">
                  <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(3)}}<hr>
                </div>

                <b>Formatos</b><br>
                <div class="mt-1">
                  <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(4)}}<br>
                </div>
                <div class="mt-1">
                  <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(5)}}<hr>
                </div>

                <b>Bloques con Detalles</b><br>
                <div class="mt-1">
                  <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(6)}}<br>
                </div>
                <div class="mt-1">
                  <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(7)}}
                </div>
                <div class="mt-1">
                  <b-icon scale="0.5" icon="circle-fill"></b-icon> {{this.getHelp(8)}}
                </div>                
              </b-tab>                
            </b-tabs>
          </b-col>

          <b-col lg="9">                                       
            <b-tabs class="mt-3">
              <b-tab title="Vista Previa" active @click="renderReports()">
                <b-row>
                  <b-col lg="12" class="mb-2">
                    <b-button class="pull-right" size="sm" @click="generateReports()">
                      <b-icon icon="printer" aria-hidden="true"></b-icon> Imprimir
                    </b-button>          
                  </b-col>
                  <b-col lg="12">
                    <div v-if="getFormatContentHTML()=='[[no-config]]'">
                      <b-alert variant="danger" show>
                        <h4 class="alert-heading">Reporte sin configurar</h4>
                        Guarde el código fuente para visualizarlo.
                      </b-alert>                        
                    </div>
                    <div v-if="getFormatContentHTML()=='[[no-report]]'">
                      <b-alert variant="warning" show>
                        <h4 class="alert-heading">No se seleccionó el reporte</h4>
                        Seleccione el repote a editar.
                      </b-alert>
                    </div>                                                             
                    <div v-if="getFormatContentHTML()=='[[no-data]]' && !filter.id">
                      <b-alert variant="info" show>
                        <h4 class="alert-heading">Cargue un ID y filtre</h4>
                        Filtre para visualizar el comprobante con un ID que tenga registros generados.
                      </b-alert>
                    </div>      
                    <div v-if="getFormatContentHTML()=='[[no-data]]' && filter.id">
                      <b-alert variant="info" show>
                        <h4 class="alert-heading">No se encontrarón datos</h4>
                        Filtre por un ID con registros generados.
                      </b-alert>
                    </div>                                                         

                    <div v-if="getFormatContentHTML()!='[[no-config]]' && getFormatContentHTML()!='[[no-report]]' && getFormatContentHTML()!='[[no-data]]'">
                      <div class="crud-reports-design-preview">
                        <div v-html="getFormatContentHTML()" class="crud-reports-design-preview-html"></div>
                      </div>
                    </div>

                  </b-col>          
                </b-row>
              </b-tab>

              <b-tab title="Diseño">
                <b-row>   
                  <b-col lg="12" class="mt-2">                      
                    <b-button variant="warning" class="pull-left" size="sm" @click="restoreDefaultReports()" v-if="!crud.form.blocked">
                      <b-icon icon="arrow-counterclockwise"></b-icon> Restaurar por Defecto
                    </b-button>                      
                    <b-button variant="dark" class="pull-right" size="sm" @click="saveReports()">
                      <i class="fa fa-save" aria-hidden="true"></i> Guardar
                    </b-button>          
                  </b-col>                                              
                  <b-col lg="12">                      
                    <prism-editor v-model="crud.form.content" language="html" class="crud-reports-design-code-editor"></prism-editor>            
                  </b-col>
                </b-row>
              </b-tab>   

              <b-tab title="Configuración de Página">
                <b-row>
                  <b-col lg="12">
                    <b-form-group label="Medidas">
                      <b-form-select v-model="crud.form.page" :options="arr.page"></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12">
                    <b-form-group label="Horientación">
                      <b-form-select v-model="crud.form.orientation" :options="arr.orientation"></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12">
                    <b-form-group label="Fuente por Defecto">
                      <b-form-select v-model="crud.form.font" :options="arr.fonts"></b-form-select>
                    </b-form-group>
                  </b-col>  
                  <b-col md="12">
                    <b-form-checkbox v-model="crud.form.blocked" 
                                      switch 
                                      size="sm" 
                                      class="pull-left">
                      Bloquea la configuración del reporte
                    </b-form-checkbox> 
                  </b-col>                                       
                  <b-col>
                    <b-button variant="dark" class="pull-right" size="sm" @click="saveReportsConfiguration()">
                      <i class="fa fa-save" aria-hidden="true"></i> Guardar
                    </b-button>                      
                  </b-col>
                </b-row>
              </b-tab>                     
            </b-tabs>
          </b-col>
        </b-row>      
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="pdfPrintReport" :pdf="pdfPrintReport" />

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Session from '@/handler/session'    
  import FindObject from '@/components/inc/find/findObject'  
  import Printer from '@/components/inc/printer/printer'
  
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  import Vue from 'vue'
  import JsonViewer from 'vue-json-viewer'
  Vue.use(JsonViewer)

  import JsonTree from 'vue-json-tree'
  Vue.component('json-tree', JsonTree)

  export default {
    components: {
      FindObject,
      PrismEditor,      
      Printer,
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.REPORTES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'design',
          elements: {}
        }, 
        crud: { 
          form: {
            id: 0,     
            reports: null,     
            content: '',         
            orientation: 'portrait',
            page: 'A4',
            font: 'Courier',
            blocked: false,
          },             
        },    
        filter: {
          id: 0,
        },
        jsonFilter: null,
        variablesUsed: null,
        contentRender: '[[no-report]]',
        pdfPrintReport: '',
        arr: {
          orientation: [
            { text: 'Vertical', value: 'portrait' },
            { text: 'Horizontal', value: 'landscape' },
          ],
          page: [
            { text: 'A4', value: 'a4' },
            { text: 'A5', value: 'a5' },
            { text: 'A6', value: 'a6' },
            { text: 'Letter', value: 'letter' },
            { text: 'Legal', value: 'legal' },
          ],
          fonts: [
            { text: 'Courier', value: 'courier' },
            { text: 'Arial', value: 'arial' },
            { text: 'Helvetica', value: 'helvetica' },
            { text: 'Times-Roman', value: 'times-Roman' },
            { text: 'Sans Serif', value: 'sans-serif' },
            { text: 'Symbol', value: 'symbol' },            
          ],
        }
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted () {                 
      // this.filterReports()      
    },
    methods: {      
      configViewerJson() {    
        setTimeout(()=>{
          var arrElement = document.getElementsByClassName("json-tree-indent");        
          if(arrElement) {
            for (var i = 0; i < arrElement.length; i++){
              if(arrElement[i].innerHTML != '&nbsp;') {
                arrElement[i].innerHTML = '&nbsp;';            
              }                        
            }                              
          }
        },500)                    
      },
      loadReports(object) {  
        this.clearReportsAll()          

        if(object){          
          this.crud.form.reports = object          
          this.crud.form.id = object.id  

          this.crud.form.orientation = object.orientation
          this.crud.form.page = object.page
          this.crud.form.font = object.font
          this.crud.form.blocked = object.blocked

          if(object.content_user) {
            this.crud.form.content = object.content_user            
            this.contentRender = '[[no-data]]'
          } else {
            this.crud.form.content = object.content     
            this.contentRender = '[[no-config]]'
          }              
        } else {
          this.clearReportsAll()          
        }        
      },
      filterReports() {
        if(this.crud.form.reports && !this.crud.form.reports.only_detail) {
          if(!this.filter.id || !this.crud.form.id) {          
            this.clearReportsRender()
            return false          
          }
        } else {
          this.filter.id = 0
        }

        var result = serviceAPI.mostrarJson(this.crud.form.id, this.filter.id)
        
        this.jsonFilter = null
        result.then((response) => {
          var data = response.data
          this.jsonFilter = data

          this.renderReports()
          this.variablesReports()
        })
        .catch(error => {          
          this.clearReportsRender()
          this.$awn.alert('No se encontró el registro ID: ' + this.filter.id)
        });                
      },
      variablesReports() {      
        if(this.crud.form.reports && !this.crud.form.reports.only_detail) {
          if(!this.filter.id || !this.crud.form.id) {
            this.clearReportsRender()
            return false          
          }
        } else {
          this.filter.id = 0
        }

        var result = serviceAPI.mostrarVariable(this.crud.form.id, this.filter.id)
        
        this.variablesUsed = null
        result.then((response) => {
          var data = response.data
          this.variablesUsed = data

          this.configViewerJson()
        })
        .catch(error => {
          this.clearReportsRender()
          this.$awn.alert('No se encontró el registro ID: ' + this.filter.id)
        });   
      },
      renderReports() { 
        if(this.crud.form.reports && !this.crud.form.reports.only_detail) {       
          if(!this.filter.id || !this.crud.form.id) {          
            return false          
          }
        } else {
          this.filter.id = 0
        }

        var result = serviceAPI.mostrarRender(this.crud.form.id, this.filter.id)

        result.then((response) => {
          var data = response.data 
          this.contentRender = data
        })
        .catch(error => {
          this.clearReportsRender()
          this.$awn.alert('No se encontró el registro ID: ' + this.filter.id)
        });                
      },      
      saveReports() {
        if(!this.crud.form.id) {
          this.$awn.alert('No se seleccionó ningun reporte')
          return false          
        }

        let loader = this.$loading.show();      
        this.crud.form.blocked = true
        var result = serviceAPI.editar(this.crud.form);
        
        result.then((response) => {          
          loader.hide()
          this.filterReports()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      saveReportsConfiguration() {
        if(!this.crud.form.id) {
          this.$awn.alert('No se seleccionó ningun reporte')
          return false          
        }

        let loader = this.$loading.show();        
        var result = serviceAPI.editarConfiguration(this.crud.form);
        
        result.then((response) => {          
          loader.hide()
          this.filterReports()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      restoreDefaultReports() {
        if(!this.crud.form.id) {
          this.$awn.alert('No se seleccionó ningun reporte')
          return false          
        }

        this.$bvModal.msgBoxConfirm('¿Desea restaurar la versión original del reporte?', {
          title: 'Restaurar Reporte',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'Restaurar',
          cancelVariant: 'outline-warning',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'warning',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {            
            let loader = this.$loading.show();        

            this.crud.form.content = this.crud.form.reports.content;
            var result = serviceAPI.editar(this.crud.form);
            
            result.then((response) => {          
              loader.hide()
              this.loadReports
              this.filterReports()
              
              this.$awn.success("Reporte restaurado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })

          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      },
      getFormatContentHTML() {   
        if( this.contentRender) {
          return this.contentRender        
        } else {
          return '[[no-config]]'
        }        
      },
      getHelp(value) {
        var arrHelp = []
        arrHelp[0] = `Para agregar una variable al reporte, se tiene que escribir el nombre de la variable entre dos llaves. EJ: {{variable}}`
        arrHelp[1] = `La variable, antes de las llave de apertura y luego del cierre de llaves, tiene que tener un espacio.`
        arrHelp[2] = `Para acceder a los objetos hijos, se puede hacer de la siguiente manera: {{customer.name}}`
        arrHelp[3] = `Se puede acceder con el punto (.) hasta la ultima propiedad disponible del objeto.`
        arrHelp[4] = `Para darle un formato al dato desde la variable, seguido al nombre de la variable, agregar el caracter pleca (|) y luego el tipo de dato. EJ: {{variable|date}}`
        arrHelp[5] = `Los formatos de datos disponibles actualmente son: date, currency, int, decimal, pointSales, numberInvoice, si-no, image, concatLeft[valor], concatRight[valor].`
        arrHelp[6] = `Para agregar un bloque con repetición, arriba del bloque a repetir, (se mantiene la sintaxis de las variables) agregar de la siguiente manera: {{details|arrayStart}} y al final del bloque {{details|arrayEnd}}`
        arrHelp[7] = `Si en el raíz, son datos de repetición, puede hacer referencia de la sigueinte manera: {{-|arrayStart}} y al final del bloque {{-|arrayEnd}}`
        arrHelp[8] = `Dentro del bloque con repetición, se mantiene la sintaxis de las variables, Partir como base de la primer propiedad hija declarada en la variable de repeticion.`
        
        return arrHelp[value]
      },
      generateReports() {
        if(this.crud.form.reports && !this.crud.form.reports.only_detail) {       
          if(!this.filter.id || !this.crud.form.id) {
            this.$awn.alert('No se encontraron datos para imprimir')
            return false          
          }
        } else {
          this.filter.id = 0
        }
        
        var result = serviceAPI.generate({
          reportsID: this.crud.form.id,
          regID: this.filter.id
        })

        result.then((response) => {
          var data = response.data      
          this.pdfPrintReport = data            
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });             
      },
      clearReportsAll() {
        this.jsonFilter = null
        this.variablesUsed = null
        this.contentRender = '[[no-report]]',
        this.pdfPrintReport = ''

        this.crud.form.reports = null
        this.crud.form.id = 0
        this.crud.form.orientation = 'portrait'
        this.crud.form.page = 'A4'
        this.crud.form.font = 'Courier'
        this.crud.form.content = ''
      },
      clearReportsRender() {
        this.jsonFilter = null
        this.variablesUsed = null
        this.contentRender = '[[no-data]]',
        this.pdfPrintReport = ''        
      }
    } 
  }
</script>
<style>
 .crud-reports-design-preview {
    background: #e5e5e5;    
    padding: 15px;
    height: 650px;        
  }
  .crud-reports-design-preview .crud-reports-design-preview-html {
    background: #fff;
    height: 650px;     
    padding: 15px;   
    overflow: overlay;
  }  
  .crud-reports-design-code-editor {
    height: 650px;
  }
  .crud-reports-design-code-editor pre {
    height: 650px;
  }  
  .reports-design-button-filter {
    margin-top: 30px;
  }
  .reports-design-pre-json {
    overflow: auto;    
    height: 680px;
    width: 100%;
    min-width: unset;
  }
  .json-tree-value {
      overflow: unset;
      text-overflow: unset;
      white-space: nowrap;
  }  
  .json-tree-collapsed {
      overflow: unset;
      text-overflow: unset;
      white-space: nowrap;    
  }
</style>