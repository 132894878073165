import { render, staticRenderFns } from "./design.vue?vue&type=template&id=2f93bc1a&"
import script from "./design.vue?vue&type=script&lang=js&"
export * from "./design.vue?vue&type=script&lang=js&"
import style0 from "./design.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports